import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'SafeLaunch',
    icon: 'FarmIcon',
    href: '/',
  },
  {
    label: 'SafeLock',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Lock LP Tokens',
        href: '/locklp',
      },
      {
        label: 'Lock Tokens',
        href: '/lockmain',
      },
    ],
  },
  // {
  //   label: 'Farms',
  //   icon: 'FarmIcon',
  //   href: '/farms',
  // },
  // {
  //   label: 'Staking',
  //   icon: 'PoolIcon',
  //   href: '/staking',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'IDO',
  //   icon: 'IfoIcon',
  //   href: '/ido',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'PancakeSwap',
  //       href: 'https://pancakeswap.info/token/0xafb2997fe9a99022e61c7e01b974e0e3d7704b02',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: 'https://www.coingecko.com/en/coins/momo-finance',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: 'https://coinmarketcap.com/currencies/momo-finance/',
  //     },
  //     {
  //       label: 'AstroTools',
  //       href: 'https://app.astrotools.io/pancake-pair-explorer/0xafb2997fe9a99022e61c7e01b974e0e3d7704b02',
  //     },
  //   ],
  // },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/MomoProtocol762/',
  //     },
  //     // {
  //     //   label: 'Docs',
  //     //   href: 'https://goosedefi.gitbook.io/momo-finance/',
  //     // },
  //     {
  //       label: 'Blog',
  //       href: 'https://medium.com/@momoprotocol',
  //     },
  //   ],
  // },
  // {
  //   label: 'Audit by TechRate',
  //   icon: 'AuditIcon',
  //   href: 'https://github.com/MomoProtocol762/momoprotocol.finance/blob/main/audits/audit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/momo-finance',
  // },
]

export default config
